<template>
  <default-layout :title="$t('agentBanner.btn.edit')">
    <v-container fluid tag="section">
      <agent-banner-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const AgentBannerForm = () =>
  import('@/components/banner-management/agent-banner/form/agent-banner-form');

export default {
  name: 'unit-type-edit',
  components: { DefaultLayout, AgentBannerForm },
  async created() {
    await this.$store.dispatch('banner/form/restoreInitialState');
    this.$store.commit('banner/form/SET_FORM_TYPE', 'EDIT');
    this.$store.commit('banner/form/SET_UUID', this.$route.params.uuid);
    await this.$store.dispatch('banner/form/fetchBaseData');
  },
  computed: {
    projectName() {
      return this.$store.state.unit.form.projectName;
    },
  },
};
</script>

<style scoped></style>
